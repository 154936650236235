<template>
  <div class="goodsActivity">
    <!-- <el-button @click="getList" type="primary">主要按钮</el-button> -->
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="../../static/debris.png" />
    <img
      class="Aboutbg"
      style="top: 44%; right: 0"
      src="../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 35%; right: 0"
      src="../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 18%; right: 0"
      src="../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 28%; left: 0"
      src="../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 56%; left: 0"
      src="../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 0; left: 0"
      src="../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 22%; right: 0"
      src="../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="top: 53%; right: 0"
      src="../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 3%; right: 0"
      src="../../static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 80px 0 80px;height: 1100px;">
            <div style="width: 100%">
              <!-- <hr /> -->
              <div class="top-title">
                <img class="home-icon" src="~static/scenery/home.png" alt="" style="width:20;height:20;">
                <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                >
                <span style="margin-left:6px;" @click="Jump('/scenicService')">勺子商城</span>
                >
                <span style="margin-left:6px;color:#509AB1">景区周边</span>
              </div>
              <div class="hr-more">
                <div>景区服务</div>
              </div>
              <div class="Hrbg2">
                 <el-tabs v-model="activeName" stretch style="width: 95%;" @tab-click="tabClick">  
                    <el-tab-pane name="402880447bc46a0e017bc48bb1cc0255">
                      <span slot="label"><div><img src="../../static/tickets.png" alt="" />门票</div></span>
                      <div class="QRcode">
                        <div v-for="item in QRcodeList" :key="item.id" class="QRcodebg" :style="{'background': 'url(' + item.picturePath + ')  no-repeat 50% 39%',}" >
                          <span>{{item.name}}</span>
                          <img src="../../static/fareBg.png" alt="" />
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane name="402880447bc46a0e017bc48bcb9e0259">
                      <span slot="label"><div><img src="../../static/carts.png" alt="" />车船</div></span>
                      <div class="QRcode">
                        <div  v-for="item in QRcodeList" :key="item.id" class="QRcodebg" :style="{'background': 'url(' + item.picturePath + ')  no-repeat 50% 39%',}" >
                          <span>{{item.name}}</span>
                          <img src="../../static/fareBg.png" alt="" />
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane name="402880447bc46a0e017bc48bf667025d">
                      <span slot="label"><div><img src="../../static/explain.png" alt="" />讲解</div></span>
                      <div class="QRcode">
                        <div v-for="item in QRcodeList" :key="item.id" class="QRcodebg" :style="{'background': 'url(' + item.picturePath + ')  no-repeat 50% 39%',}" >
                          <span>{{item.name}}</span>
                          <img src="../../static/fareBg.png" alt="" />
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
              </div>
              
            </div>
          </div>
          <div id="geography" class="geography" style="margin-bottom: 300px">
            <div class="hr-more">周边服务</div>
            <div class="QRcode" style="justify-content:space-between">
              <div v-for="(item,index) in aroundServiceList" :key="item.id">
                <img style="cursor: pointer;" @click="goProject(item.url)" :src="bgList[index].src" alt="" />
                <p>{{item.name}}</p>
                <div>
                  <a class="morebg" target="_blank" :href="item.url" >查看更多</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="backTopBG"
          style="
            position: fixed;
            display: none;
            right: 56px;
            color: #509ab1;
            font-weight: bold;
            text-align: center;
            bottom: 130px;
            z-index: 9;
          "
          @click="backTop()"
        >
          <img src="../../static/backTopbg.png" alt="" />
          <p>回到顶部</p>
        </div>
        <leftFloat :floatList="floatList" />
        
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

export default {
  name: "About",
  data() {
    return {
      floatList: {
        list: [
          { name: "景区服务", url: "/scenicService?name=景区服务" },
          { name: "周边服务", url: "/scenicService?name=周边服务" },
          { name: "勺子商城", url: "https://shop565612588.taobao.com/" },
        ],
        title: "勺子商城",
      },
      bgList: [
        { src: require("static/stay.png") },
        { src: require("static/catering.png") },
        { src: require("static/recreation.png") },
        { src: require("static/specialty.png") },
      ],
      activeName:`402880447bc46a0e017bc48bb1cc0255`,
      mySwiper: null,
      slideshowList: ``,
      QRcodeList:``,
      aroundServiceList:``,
    };
  },
  methods: {
    goProject(src) {
      window.open(src, "_blank");
    },
    tabClick(){
      api.scenicService({scenicServiceTypeId:this.activeName}).then(({data}) => {
        this.QRcodeList = data
        console.log(data);
      })
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    // api.scenicServiceType().then(({data})=>{
    //   data.forEach(element => {
    //     api.scenicService({scenicServiceTypeId:element.id}).then(({data}) => {
    //       console.log(data);
    //     })
    //   });
    // })
    api.scenicService({scenicServiceTypeId:this.activeName}).then(({data}) => {
      this.QRcodeList = data
    })
    api.aroundService().then(({data}) => {
      this.aroundServiceList = data
    })
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>
<style scoped>
>>>.el-tabs__active-bar{
  background-color: #509AB1;
}
>>>.el-tabs__item{
  height: 111px !important;
  line-height: 105px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #509ab1;
}
.QRcode {
  width: 1200px;
  margin: 50px auto;
  display: flex;
  justify-content: start;
}
.QRcode > .QRcodebg {
  position: relative;
  /* background: url(../../static/QRcode.png) no-repeat 50% 39%; */
}
.QRcodebg>span{
  position: absolute;
  margin: -25px 94px;
  white-space: nowrap;
  color: #509AB1;
  font-weight: bold;
  letter-spacing: 3px;
}
.QRcode > div > P {
  line-height: 50px;
  letter-spacing: 5px;
  font-weight: bold;
  text-align: center;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.PastPhotos {
  background: url(../../static/PastPhotosbg.png) no-repeat;
  height: 737px;
}
.imgBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* height: 950px;
  overflow-y: auto; */
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
.top-title {
  /* height: 130px; */
  background-image: url(/static/img/title.ab49d158.png);
  background-size: cover;
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.morebg {
  display: block;
  width: 201px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #509ab1;
  margin: 0 auto;
  background: url("../../static/morebg.png") no-repeat;
}
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("../../static/tab.png") no-repeat;
}
.tabsB {
  background: url("../../static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.Hrbg2 {
  display: flex;
  justify-content: space-evenly;
  width: 1241px;
  height: 151px;
  line-height: 135px;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;

  color: #509ab1;
  background: url("../../static/Hrbg3.png") no-repeat;
}
.Hrbg2 img {
  vertical-align: middle;
  padding-right: 10px;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  position: absolute;
  left: -50vw;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}
.greatDeeds_bzt {
  height: 100%;
  position: relative;
  left: 50%;
  width: 2px;
  border: none;
  background: darkgray;
}
.greatDeeds_bzt > h2 {
  position: absolute;
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.greatDeeds {
  background: url("../../static/greatDeedsimg.png") no-repeat center;
  background-size: cover;
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("../../static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../../static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}

.introduceText {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}
.introduce {
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("../../static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.goodsActivity {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("../../static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  /* text-indent: 28px; */
  height: 339px;
  writing-mode: tb-rl;
  letter-spacing: 12px;
}
.generalImg {
  flex: 1;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>